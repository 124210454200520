.surveyContainer {
  background-color: #fff;
  position: relative;
}

.surveyHeader {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 16px;
  font-weight: 700;
}

.surveyResultsWrapper {
  height: calc(100% - 50px);
  overflow-y: auto;
  width: 100%;
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
}

.surveyResultsItem {
  display: flex;
  flex-direction: column;
}

.surveyResultProgressBar {
  height: 10px;
  min-width: 1%;
  background-color: #d3d3d3;
  margin-right: 15px;
}

.surveyResultProgressBarWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 15px;
}

.surveyResultProgressBarWrapper span {
  color: #d4d4d4;
  font-size: 14px;
}

.surveyResultLoading {
  position: absolute;
  top: 44px;
  left: 0;
  height: 6px;
  width: 100%;
}
