.table table,
.table tbody,
.table thead {
  display: block;
}

.table tr {
  display: flex;
  align-items: center;
  min-width: calc(100vw - 60px);
  cursor: pointer;
}

.table tbody tr:hover {
  background-color: #eee;
  border-top: 1px solid;
  border-color: lightgrey;
}

.table thead {
  width: 100%;
}

.table tbody {
  height: calc(100vh - 250px);
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

td,
th {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 20px;
}

.table {
  padding: 0 30px;
}

.tableWrapper {
  background: #fff;
}

.table .tableWrapper {
  height: calc(100vh - 190px);
  max-height: 660px;
  position: relative;
}

.table thead tr {
  height: 60px;
  background: #eee;
}

.table thead th {
  font-size: 18px;
}

.table tbody tr {
  height: 50px;
  border-bottom: 1px solid #eee;
  font-size: 16px;
}

.tableParticipants thead th,
.table thead th {
  text-align: left;
}

.tableLoading {
  position: absolute;
  top: 60px;
  width: 100%;
}

.tableParticipants .tableLoading {
  top: 90px;
}

.table .topicType {
  padding-left: 20px;
  width: 9%;
}

.table .topicDescription {
  width: 18%;
}

.table .topicComments,
.table .topicLikes,
.table .topicName,
.table .topicIsHidden {
  width: 9%;
}

.table .topicParticipants {
  width: 8%;
}

.table .topicCreatedAt,
.table .topicUpdatedAt,
.table .topicAuthor {
  width: 12%;
}

table {
  border-collapse: collapse;
  width: 100%;
}

/* participants table */
.tableParticipants thead tr {
  height: 40px;
  background: #eee;
}

.tableParticipants tbody tr {
  height: 35px;
  border-bottom: 1px solid #eee;
  cursor: default;
}

.tableParticipants .participantsEmail {
  width: 40%;
  padding-left: 20px;
}

.tableParticipants .participantsName {
  width: 30%;
}

.tableParticipants .participantsStatus {
  overflow: visible;
}

.tableEmpty table tr {
  border: none;
}

.iconCellWrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}

/* reports table */
.reportId {
  width: 20%;
  padding-left: 20px;
}

.reportCategory {
  width: 20%;
}

.reportType {
  width: 20%;
}

.reportedBy {
  width: 20%;
}

.reportedAt {
  width: 20%;
}

/* moderated messages table */
.message {
  padding-left: 20px;
  width: 20%;
}

.senderId {
  width: 15%;
}

.recipientId {
  width: 15%;
}

.attachments {
  width: 20%;
}

.customParameters {
  width: 15%;
}

.dateSent {
  width: 15%;
}

.columnHeaderWrapper {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.columnHeader {
  font-size: 18px;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
