.userDropdownWrapper {
  position: relative;
  font-size: 16px;
}

.userDropdownWrapper span {
  cursor: pointer;
}

.userDropdownWrapper span:hover {
  text-decoration: underline;
}

.userDropdownWrapper a {
  color: #000;
}

.userDropdownWrapper a:hover {
  color: #000;
  text-decoration: underline;
}

.userDropdownList {
  position: absolute;
  top: 20px;
  left: -20px;
  background: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  padding: 10px;
  z-index: 10;
}

.userDropdownList li {
  margin-bottom: 10px;
  cursor: pointer;
}
