.pagination.ui.pagination.menu {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  box-shadow: none;
}

.pagination a {
  outline: none;
  margin: 0 5px;
  padding: 8px 15px;
  cursor: pointer;
  color: #000;
}

.paginationDisabled a,
.paginationDisabled .active a {
  pointer-events: none;
  color: #606060;
  border-color: #606060;
}

.pagination.ui.pagination.menu .active.item {
  border: 1px solid #000;
  border-radius: 50%;
  background: none;
  padding: 11px 15px;
  min-width: auto;
}

.disabled a {
  color: #606060;
}

.pagination.ui.menu {
  background: none;
  border: none;
}

.pagination.ui.menu a:before {
  display: none;
}

.pagination.ui.menu a:hover,
.pagination.ui.menu .item:hover {
  background: none;
}
