.topicPage {
  width: 100%;
  min-height: 800px;
  background: #d3d3d3;
  position: relative;
}

.topicPageTop {
  width: 100%;
  height: 50px;
  padding: 0 25px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topicPageContentWrapper {
  padding: 0 30px 20px;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.topicPageNav {
  display: flex;
  align-items: center;
}

.topicPageGoBack {
  font-size: 20px;
  font-weight: 700;
}

.topicPageArrowBtn {
  cursor: pointer;
  height: 30px;
  width: 30px;
  background: url(../../../assets/icons/arrow.svg) no-repeat top center / contain;
}

.testBtn {
  position: absolute;
  top: 30px;
  left: 500px;
}

.topicPageLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.topicPageContentItem {
  width: 49.5%;
  height: 100%;
  max-height: 49%;
  min-height: 320px;
}

.topicPageContentItem:nth-child(even) {
  margin-left: 1%;
}

.topicPageContentItem:first-child {
  margin-bottom: 1%;
}

.topicPageContentItem:nth-child(2) {
  margin-bottom: 1%;
}
