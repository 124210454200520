.authWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  position: relative;
  width: 100vw auto;
  height: 100vh auto;

  /* AlloCare/Graybase/White */

  background: #ffffff;
}

.authSection {
  /* Login container */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;

  width: auto 440px;
  height: auto 650px;

  padding: 1em;
  padding-left: 0.5em;
  margin-top: 3em;
  margin-bottom: auto;
  padding-top: -20px;
  /* AlloCare/Graybase/White */

  background: #ffffff;
  /* CareDx/Border & Divider/border ←↕︎→ 1px */

  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.125), inset -1px 0px 0px rgba(0, 0, 0, 0.125),
    inset 0px 1px 0px rgba(0, 0, 0, 0.125), inset 1px 0px 0px rgba(0, 0, 0, 0.125);
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
}

.authForm {
  width: 250px;
  min-height: 250px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
}

.formList {
  width: 100%;
  margin-top: 20px;
}

.formListItem {
  margin-top: 10px;
  margin-right: 10px;
}

.formListItem input {
  height: 18px;
  width: 100%;
  font-size: 14px;
  padding-left: 4px;
  outline: none;
  border: 1px solid #000;
}

.formListItem:first-child {
  margin-bottom: 20px;
  position: relative;
}

.formListItem:last-child {
  display: flex;
  justify-content: center;
}

.formListItemRequired {
  composes: formListItem;
}

.formListItemRequired input {
  border: 1px solid rgb(255, 100, 100);
}

.errorMessage {
  color: rgb(255, 100, 100);
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 58px;
  left: 0;
}

.alreadyLinkedWrapper {
  /* Login container */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 40px 40px 0px;
  gap: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;

  width: 785px;
  height: 525px;

  /* AlloCare/Graybase/White */

  background: #ffffff;
  /* CareDx/Border & Divider/border ←↕︎→ 1px */
  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.alTextContainer {
  /* Frame 731 */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  width: 361px;
  height: 360px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.alreadyMappedContainer {
  /* image 2 */

  width: 304px;
  height: 485px;

  background: url(alreadyLinkedSC.png);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.alYouAreConnected {
  width: 361px;
  height: 48px;

  /* CareDx/Body/Regular 3 */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.alFnLn {
  /* First name, Last name */

  width: 361px;
  height: 24px;

  /* CareDx/Heading/H9 - Bold */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.alOpenApp {
  /* Please open the AlloCare app on your mobile device to view and accept the medication list from MAP Pro. */

  width: 361px;
  height: 72px;

  /* CareDx/Body/Regular 3 */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #1e1e1e;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.alQuestions {
  /* If you have any questions about viewing the medication list from MAP or other information in the AlloCare app, please contact our Customer Support​ team at 1-888-255-6227. */

  width: 361px;
  height: 96px;

  /* CareDx/Body/Regular 3 */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #1e1e1e;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.broughtByCareDxWrapper {
  /* Frame 722 */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  width: 250px;
  height: 72px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.broughtByCareDxLogo {
  /* CareDx-logo */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 140.67px;
  height: 40px;

  /* Inside auto layout */
  scale: 3.5;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.broughtByCareDxText {
  /* Brought to you by */

  width: 102px;
  height: 15px;

  /* AlloCare/Small */

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  /* identical to box height, or 171% */

  color: #1e1e1e;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.titleSubtitle {
  /* Frame 732 */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;

  width: 361px;
  height: 112px;

  line-break: normal;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.yesIWantToConnect {
  /* Yes, I want AlloCare to connect with my healthcare provider. */

  width: 361px;
  height: 48px;

  /* CareDx/Heading/H9 - Bold */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  margin-bottom: 12px;
  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.weRetrieved {
  /* We retrieved this information from your AlloCare account. */

  width: 361px;
  height: 48px;

  /* CareDx/Body/Regular 2 */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.formItem {
  /* form-item/vertical/select-multiple */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;

  width: 361px;
  height: 86px;

  /* General/HitBox

Hitbox makes whole element selectable when press & hold Ctrl key
It also be used in case we want to apply drop shadow effect but still want to keep the transparent background.
*/
  background: rgba(255, 255, 255, 1e-6);

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.formItemLabel {
  /* label */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 10px 8px;
  gap: 4px;

  font-size: large;
  width: 361px;
  height: 32px;

  /* General/HitBox

Hitbox makes whole element selectable when press & hold Ctrl key
It also be used in case we want to apply drop shadow effect but still want to keep the transparent background.
*/
  background: rgba(255, 255, 255, 1e-6);

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.formItemLabel::after {
  content: '*';
  color: red;
}

.formRequired {
  font-size: small;
  float: right;
  align-self: flex-end;
  padding-right: 20px;
}
.formRequired::before {
  content: '*';
  color: red;
}

.formItemValue {
  /* select */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px 13px;
  margin-left: 10px;
  width: 361px;
  height: 38px;

  /* General/_Others/Gray/White */

  background: #ffffff;
  /* General/_Others/Gray/400 */

  border: 1px solid #ced4da;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.formItemValue input:focus {
  outline: none;
}

.formItemButton {
  /* button

Button with styles for actions in forms, dialogs, and more with support for multiple sizes, states, and more.
*/

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  margin-left: 10px;
  width: 100%;
  height: 40px;

  /* General/_Others/Theme/Primary */

  background: #1f8ecc;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.cInputArea {
  /* Frame 713 */

  /* Auto layout */
  padding-right: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Inside auto layout */

  order: 1;
  flex-grow: 1;
}

.cInputAreaFormWithErrors {
  margin-top: 2em;
}

.generalError {
  /* CareDx/Body/Regular 3 */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  /* AlloCare/Red/CareDX - #CB333B */

  color: #cb333b;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.specificError {
  /* text */

  width: 361px;
  height: 48px;
  margin-left: 10px;
  /* CareDx/Body/Small */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  /* AlloCare/Red/CareDX - #CB333B */

  color: #cb333b;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.tyContainer {
  /* Login container */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 0px;
  gap: 32px;
  font-size: 16px;
  width: 441px;
  height: 709.5px;

  /* AlloCare/Graybase/White */

  background: #ffffff;
  /* CareDx/Border & Divider/border ←↕︎→ 1px */

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.tyTitle {
  /* Thank you */

  width: 361px;
  height: 24px;

  /* CareDx/Heading/H9 - Bold */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.tyDesc {
  width: 361px;
  height: 144px;

  /* CareDx/Body/Regular 3 */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: rgba(0, 0, 0, 0.8);

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.tyImg {
  width: 304px;
  height: 437.5px;

  background: url(alreadyLinkedSC.png);

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
}

.suTyAppStore {
  background-position: center center;
  background-size: cover !important;
  background: url(downloadOnAppStore.png);
  /* Inside auto layout */
  width: 130.07px !important;
  height: 42.08px !important;
  /* Inside auto layout */
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.suTyPlayStore {
  background: url(downloadOnPlayStore.png);
  background-position: center center;
  background-size: cover !important;
  /* Inside auto layout */
  width: 150.07px !important;
  height: 42.08px !important;
  /* Inside auto layout */
  cursor: pointer;
  flex: none;

  flex-grow: 0;
}

.suTyQRCode {
  background: url(downloadQR.png);

  background-position: center center;
  background-size: cover !important;
  /* Inside auto layout */
  width: 74px !important;
  height: 73px !important;

  /* Inside auto layout */

  flex: none;

  flex-grow: 0;
}

.dontHaveAccountWrapper {
  /* Don't have an account? Sign up here */

  width: 361px;
  height: 48px;

  /* CareDx/Body/Underline 2 */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  margin-bottom: 1em;
  margin-top: -1em;
  /* CareDx/Primary Palette/CareDx Black - #231f20 */

  color: #231f20;

  /* Inside auto layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
}

.anyQuestionsText {
  /* If you have any questions about using the AlloCare app, please contact our Customer Support team at 1-888-255-6227. */

  width: 361px;
  height: 72px;

  /* CareDx/Body/Regular 3 */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  padding-right: 1em;
  padding-left: 1em;

  color: #1e1e1e;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.su2FContainer {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  gap: 40px;

  max-width: 441px;
  height: 510px;

  /* AlloCare/Graybase/White */

  background: #ffffff;
  /* CareDx/Border & Divider/border ←↕︎→ 1px */

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.su2FHiUser {
  height: 24px;

  /* CareDx/Heading/H9 - Bold */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.su2WeWillText {
  /* CareDx/Heading/H8 - Regular */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  /* or 120% */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.su2SmsAuthText {
  max-width: 361px;
  height: 48px;

  /* CareDx/Body/Regular 2 */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  align-self: flex-start;
}

.su2fForm {
  /* Frame 731 */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  max-width: 361px;
  height: 110px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
}

.su2fEnterCodeLabel {
  /* label */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 8px;
  gap: 4px;

  max-width: 361px;
  height: 32px;

  /* General/HitBox

Hitbox makes whole element selectable when press & hold Ctrl key
It also be used in case we want to apply drop shadow effect but still want to keep the transparent background.
*/
  background: rgba(255, 255, 255, 1e-6);

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.su2fEnterCodeFieldContainer {
  /* input-number */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  max-width: 361px;
  height: 38px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.su2fEnterCodeField {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 13px;

  width: 261px;
  height: 38px;

  /* General/_Others/Gray/White */

  background: #ffffff;
  /* General/_Others/Gray/400 */

  border: 1px solid #ced4da;
  border-radius: 4px 0px 0px 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}

.su2fEnterCodeButton {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 13px;
  gap: 12px;
  cursor: pointer;
  max-width: 100px;
  height: 38px;

  /* General/_Others/Gray/200 */

  background: #e9ecef;
  /* CareDx/Components/Input/Addon Right Border ↕︎→ */

  border-color: #00000001;
  border-radius: 0px 4px 4px 0px;

  /* box-shadow: inset -1px 0px 0px #ced4da, inset 0px 1px 0px #ced4da, inset 0px -1px 0px #ced4da !important; */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  /* CareDx/Body/Regular 3 */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* identical to box height, or 150% */

  display: flex;
  align-items: center;
}

.su2fStandardMessageApply {
  /* Standard message and data rates may apply. */

  max-width: 361px;
  height: 24px;

  /* CareDx/Body/Small */

  font-family: 'Trade Gothic LT Std';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  color: #000000;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.su2fVerifyButton {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  cursor: pointer;

  max-width: 361px;
  height: 40px;

  /* General/_Others/Theme/Primary */

  background: #1f8ecc;
  border-radius: 4px;
  border: none;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  color: #ffffff;
}

.suLoadingImage {
  margin-top: 20%;
  max-width: 10%;
  margin: auto;
}
.suLoadingText {
  text-align: center;
  margin: auto;
  margin-bottom: 20%;
  /* AlloCare/Body 4 */

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.passwordInputInput {
  width: 100%;
  border: none;
  font-size: large;
  float: left;
}

.passwordInputToggle {
  align-self: flex-end;
  vertical-align: text-top;
  position: absolute;
  cursor: pointer;
  z-index: 2;
}

@media (min-width: 768px) {
  .authForm {
    width: 320px;
  }

  .authSection {
    font-size: 20px;
  }

  .formListItem input {
    height: 24px;
    width: 100%;
    font-size: 16px;
  }

  .formListItem:last-child {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .authForm {
    width: 400px;
  }
}

@media (max-width: 768px) {
  .alreadyLinkedWrapper {
    /* Login container */

    /* Auto layout */

    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 40px 40px 0px;
    gap: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 350px;
    margin-bottom: auto;

    width: 100vw;
  }

  .alTextContainer {
    /* Frame 731 */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;

    width: 90vw;
    height: 360px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .alTextContainer div {
    width: 90% !important;
  }
  .alreadyMappedContainer {
    align-self: center;
  }

  .authSection {
    width: 100% !important;
  }
}
