.header {
  width: 100%;
  height: 50px;
  background-color: #d3d3d3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 0px 30px;
}

.logo {
  cursor: pointer;
  font-size: 20px;
  line-height: 20px;
  color: #000;
}

.logo:hover {
  color: #000;
}

.logoutBtn {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
}
