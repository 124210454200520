.message {
  display: flex;
  flex-direction: column;
  width: fit-content;
  max-width: 600px;
  min-width: 230px;
}

.reported {
  composes: message;
}

.admin {
  composes: message;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
}

.moderator {
  composes: message;
  margin-left: auto;
}

.messageWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  padding-right: 30px;
}

.messageWrapper:hover {
  background: #b8b8b8;
}

.messageTop {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  font-size: 12px;
}

.messageText {
  background: #fff;
  border-radius: 20px 20px 20px 0;
  padding: 10px 15px;
  font-size: 18px;
  word-break: break-word;
}

.messageTextReported {
  composes: messageText;
  background: #333333;
  color: #fff;
}

.messageUserName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 80%;
}

.messageDeleteIcon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: #565656;
  mask: url(../../../../assets/icons/delete.svg) no-repeat center / contain;
  --webkit-mask: url(../../../../assets/icons/delete.svg) no-repeat center / contain;
}

.messageDeleteIcon:hover {
  background-color: darkred;
}

.messageDeleteIconDisabled {
  composes: messageDeleteIcon;
  cursor: not-allowed;
}
