.sortArrow {
  min-width: 16px;
  height: 30px;
  background-color: #7a7a7a;
  cursor: pointer;
  mask: url(../../../assets/icons/sort_icon.svg) no-repeat center / cover;
  --webkit-mask: url(../../../assets/icons/sort_icon.svg) no-repeat center / cover;
  margin-left: 5px;
}

.sortArrowDisabled {
  composes: sortArrow;
  background-color: #7a7a7a70;
  pointer-events: none;
}
