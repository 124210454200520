.participantsContainer {
  background: #fff;
  position: relative;
}

.participantsHeader {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.participantsHeading {
  font-size: 16px;
  font-weight: 700;
}
