.authWrapper {
  width: 100vw;
  height: 100vh;
  background-color: #d3d3d3;
}
.authSection {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding-bottom: 70px;
  font-family: Trade Gothic LT Std;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
}

.authForm {
  width: 250px;
  min-height: 250px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: relative;
}

.formList {
  width: 100%;
  margin-top: 20px;
}

.formListItem {
  margin-top: 10px;
}

.formListItem input {
  height: 18px;
  width: 100%;
  font-size: 14px;
  padding-left: 4px;
  outline: none;
  border: 1px solid #000;
}

.formListItem:first-child {
  margin-bottom: 20px;
  position: relative;
}

.formListItem:last-child {
  display: flex;
  justify-content: center;
}

.formListItemRequired {
  composes: formListItem;
}

.formListItemRequired input {
  border: 1px solid rgb(255, 100, 100);
}

.errorMessage {
  color: rgb(255, 100, 100);
  font-size: 14px;
  line-height: 16px;
  position: absolute;
  top: 58px;
  left: 0;
}

@media (min-width: 768px) {
  .authForm {
    width: 320px;
  }

  .authSection {
    font-size: 20px;
  }

  .formListItem input {
    height: 24px;
    width: 100%;
    font-size: 16px;
  }

  .formListItem:last-child {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 768px) {
  .authForm {
    width: 400px;
  }
}
