.header {
  /* width: 100%;
  height: 50px;
  background-color: #d3d3d3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 0px 30px; */
  /* Frame 714 */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 40px;
  gap: 10px;

  width: 100vw;
  height: 76px;

  /* CareDx/Primary Palette/CareDx Gray - #53565A */

  background: #53565a;
  /* CareDx/Border & Divider/divider ↓ */

  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.125);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  position: sticky;
  top: 0;
  z-index: 80;
}

.logo {
  /* AlloCare®_Logo_WHITE 1 */

  width: 168px;
  height: 28px;

  background: url(AlloCareLogo.svg);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.logo:hover {
  color: #000;
}

.logoutBtn {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
}
