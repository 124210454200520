.notification {
  z-index: 1;
  position: absolute;
  right: 40px;
  top: 40px;
  padding: 17px 30px 11px 30px;
  max-width: 400px;
  display: flex;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.35);
}

img {
  max-width: 38px;
  margin-right: 17px;
}

.messageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
}

.textSubheader {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
}
p {
  word-break: break-word;
}

.notificationError {
  composes: notification;
  border: 2px solid #fe565e;
}
.notificationWarning {
  composes: notification;
  border: 2px solid #0080ff;
}
.notificationSuccess {
  composes: notification;
  border: 2px solid #4bc737;
}

.enter {
  opacity: 0;
  transform: scale(0.9);
  transform: translateY(-100%);
}
.enterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1000ms, transform 1000ms;
}
.exit {
  opacity: 1;
  transform: translateY(0%);
}
.exitActive {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 1000ms, transform 1000ms;
  transform: translateY(-100%);
}

.iconClose {
  width: 16px;
  height: 16px;
  background-color: #8a9197;
  mask: url(../../../assets/icons/close.svg) no-repeat center / contain;
  --webkit-mask: url(../../../assets/icons/close.svg) no-repeat center / contain;
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
  cursor: pointer;
}

.empty {
  opacity: 0;
}
