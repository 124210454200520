.profile {
  width: 100%;
  height: 90vh;
}

.editProfileForm,
.inputWrapper,
.blankImgBox,
.wrapper,
.half,
.profileInfo,
.imageLabel {
  display: flex;
  align-items: center;
}

.profile h2 {
  margin: 0;
  padding: 0;
  line-height: 40px;
}

.profileImg {
  margin: 0;
  padding: 0;
  max-width: 200px;
  max-height: 200px;
}

.editProfileForm {
  width: 100%;
  height: 100%;
  padding: 0% 10% 0% 10%;
  justify-content: center;
}

.inputWrapper {
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 50%;
}

.blankImgBox {
  background-color: #d3d3d3;
  border: 1px dashed black;
  width: 200px;
  height: 200px;
  justify-content: center;
}

.wrapper {
  width: 100%;
  height: 100%;
  background-color: #d3d3d3;
  position: relative;
}

.half {
  width: 50%;
  height: 100%;
  justify-content: center;
}

.half:nth-child(1)::after {
  content: ' ';
  width: 1px;
  height: 90%;
  background-color: black;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.profileInfo {
  width: 50%;
  height: 50%;
  flex-direction: column;
  justify-content: space-evenly;
}

.imageLabel input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.imageLabel {
  cursor: pointer;
  flex-direction: column;
}

.imageLabel:focus,
.imageLabel input:focus,
.imageLabel:hover,
.imageLabel input:hover {
  transform: scale(101%);
  transition: all 100ms ease-in-out;
}
