@font-face {
  font-family: Trade Gothic LT Std;
  src: url('assets/AllocareFont.otf') format('opentype');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Trade Gothic LT Std' !important;
  color: black;
}

code {
  font-family: 'Trade Gothic LT Std' !important;
}

.auth-content {
  border: none !important;
  padding-bottom: 0px !important;
}

.auth-container .main-container .no-beacon {
  border: none !important;
}
.auth-container {
  margin-bottom: 0px;
  border: none !important;
  box-shadow: none !important;
}
.custom-checkbox {
  display: none;
}

.auth-header {
  border: none !important;
  display: none;
}

#okta-sign-in {
  margin-top: 10px !important;
  margin-bottom: auto !important;
}
#okta-sign-in > div {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.login.okta-form-title {
  text-indent: -9999px;
  line-height: 0; /* Collapse the original line */
}

.login.okta-form-title::after {
  content: attr(data-content);
  text-indent: 0;
  display: flex;
  line-height: initial; /* New content takes up original line height */
  font-weight: bolder;
  font-size: larger;
  margin-top: -2em;
  margin-bottom: 0em;
}

.mfa.okta-form-title {
  text-indent: -9999px;
  line-height: 0; /* Collapse the original line */
}

.mfa.okta-form-title::after {
  content: attr(data-content);
  text-indent: 0;
  display: flex;
  line-height: initial; /* New content takes up original line height */
  font-weight: bolder;
  font-size: larger;
  margin-top: -2em;
  margin-bottom: 3em;
}

[for='okta-signin-username'] {
  text-indent: 0;
  display: flex;
  line-height: initial; /* New content takes up original line height */
  color: black;
}

[for='okta-signin-username']::after {
  content: '*';

  color: red;
}
[for='okta-signin-password']::after {
  content: '*';

  color: red;
}

.o-form-input-error {
  display: none !important;
}

#okta-signin-submit {
  height: 40px !important;
  margin-bottom: 1em;
}
.social-auth-apple-button {
  height: 40px !important;
  line-height: 40px !important;
  /* background: #23201F !important; */
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAhGVYSWZNTQAqAAAACAAFARIAAwAAAAEAAQAAARoABQAAAAEAAABKARsABQAAAAEAAABSASgAAwAAAAEAAgAAh2kABAAAAAEAAABaAAAAAAAAANgAAAABAAAA2AAAAAEAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAFKADAAQAAAABAAAAFAAAAAD/5JUaAAAACXBIWXMAACE4AAAhOAFFljFgAAABWWlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iWE1QIENvcmUgNS40LjAiPgogICA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPgogICAgICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyI+CiAgICAgICAgIDx0aWZmOk9yaWVudGF0aW9uPjE8L3RpZmY6T3JpZW50YXRpb24+CiAgICAgIDwvcmRmOkRlc2NyaXB0aW9uPgogICA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgpMwidZAAABuElEQVQ4EZWTTSsHURTGx2ukLEjYsFJKKWUtW4myk2Jp5yPIyp6VBWHvG8iGlZKUspSlsLAg72+/5859xjSZ5u/Ub86595557rlvSVKbNcS0dvwWnENX7KuPvmbnH7r54wy+4QTqQGaftkq+SlJVErPgOrHExCLIXHna+uNroeJQJx0WWy0Mloq6EuU3wwD0qYFpomUYA+3jKAxCqeXFlsi6hDd4hANYgHHYhHtQta9wClMgk0bYz7zYNp1e2n/8tBSxpuxDMA8S+QBVp/grxu94oao+Qf3KU45W0AOyfHHJER1KeIlecRkS1dgdWEz7nlk/kQaV5OrKxNTv6jaIZS2p+y2xg4622Bk21gklXqKyq9SFbQih1+x9ieM1u9Ln14vENWjmWpbsPbzITR1OONdODqOgTlLCVVh0LScSXk1j7FiJIroeuhZVgsqx6B6xzyA8M9rJMDyDhHyKVaK+Ylp6KwTTqfqB7xJLpLiPqqZYtdqeeJZYlt1FL1uHU7yPfh2aSNuhySTm1ewT23xrQtunNEmraqkevyG3P6r5/9hMnSudoXkLD7ADEzACc6CKnuAYhkCWif0ACGS3FOn5HwUAAAAASUVORK5CYII=)
      no-repeat 10px,
    linear-gradient(90deg, #fff 50px, #fbfbfb 0);
  filter: invert(100%);
  border-radius: 4px !important;
  color: black !important;
  font-weight: bolder;
}

.social-auth-google-button {
  height: 40px !important;
  border-radius: 4px !important;
  line-height: 40px !important;
}

[data-se='signout-link'] {
  color: #1f8ecc !important;
  text-decoration: underline !important;
  cursor: pointer;
  float: left;
  z-index: 100;
}

.cokta-forgot-pass {
  color: #1f8ecc !important;
  text-decoration: underline;
  cursor: pointer;
}

[data-se='sms-send-code'] {
  /* width: 74px !important;
  font-size: x-small !important; */
  /* input-addon-right */

  /* Auto layout */

  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  padding: 7px 13px !important;

  width: 100px !important;
  height: 38px !important;

  /* General/_Others/Gray/200 */

  background: #e9ecef !important;
  /* CareDx/Components/Input/Addon Right Border ↕︎→ */

  box-shadow: inset -1px 0px 0px #ced4da, inset 0px 1px 0px #ced4da, inset 0px -1px 0px #ced4da !important;
  border-radius: 0px 4px 4px 0px !important;
  font-family: 'Trade Gothic LT Std';
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: small !important;
  line-height: 12px !important;
}

.auth-passcode {
  min-width: calc(100% - 100px) !important;
  max-width: calc(100% - 100px) !important;
  height: 38px !important;
}

.auth-passcode span {
  width: 100%;
  height: 38px !important;
}

.auth-passcode .o-form-input {
  width: 100%;
  height: 38px !important;
}

[value='Verify'] {
  width: 100%;
  height: 38px !important;
}

.auth-footer {
  display: none;
}

.okta-form-subtitle {
  text-align: left;
  font-weight: bolder !important;
}
.CONF_DOB_INPUT[value='']:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}

.CONF_DOB_INPUT[data-prettyvalue]:not([value=''])::before {
  content: attr(data-prettyvalue) !important;
  color: black;
}
.CONF_DOB_INPUT[value][data-prettyvalue]:not([value='']) {
  /* color: white; */
}

.CONF_DOB_INPUT[value]:not([data-prettyvalue]):not([value='']):focus {
  /* color: white !important; */
}
.CONF_DOB_INPUT[value=''] {
  content: '' !important;
  color: white;
}

.CONF_DOB_INPUT[value='']:focus {
  content: '' !important;
  color: black;
}

/* .CONF_DOB_INPUT[data-prettyvalue]:not([value=''])::before {
  content: attr(data-prettyvalue) !important;
  color: black;
} */

.CONF_DOB_INPUT:focus::before {
  content: '' !important;
  color: black;
}
.CONF_DOB_INPUT:focus {
  /* color: white; */
}

.js-enroll-phone {
  width: 100% !important;
}

.enroll-sms-phone {
  width: 100% !important;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  input[type='date'] {
    -webkit-min-logical-width: 100%;
    height: 100%;
    text-align: left;
  }
  .CONF_DOB_INPUT[value='']:before {
    content: attr(placeholder) !important;
    color: #aaa;
    margin-right: 0.5em;
    width: 100%;
  }

  input::-webkit-date-and-time-value {
    text-align: left;
    color: black;
  }
}

.o-form-explain::before {
  content: attr(data-content);
  white-space: pre;
}

.o-form-explain {
  text-align: left !important;
  font-weight: bolder !important;
}

main {
  border: none;
}
