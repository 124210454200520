.messagesContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
  position: relative;
}

.messagesHeader {
  width: 100%;
  min-height: 50px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.messagesHeading {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
}

.messagesContent {
  background: #eeeeee;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}

.messagesRefreshBtn {
  width: 100%;
  background: #fff;
  min-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  outline: none;
}

.messagesRefreshBtnDisabled {
  composes: messagesRefreshBtn;
  background-color: #ebebeb;
  pointer-events: none;
}

.messagesRefreshIcon {
  height: 30px;
  width: 30px;
  background: url(../../../assets/icons/refresh.svg) no-repeat top center / contain;
}

.messagesFormItem {
  font-size: 14px;
  margin-left: 20px;
  width: 240px;
  /* position: relative; */
}

.messagesFormItem select {
  height: 30px;
  border: 1px solid #777;
  min-width: 60%;
  outline: none;
}

.messagesForm {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.messagesLoading {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
}

.usersSuggestions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 31px;
  right: 0;
  width: 250px;
  z-index: 9;
  background: #fff;
  min-height: 90px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.usersSuggestionsItem {
  min-height: 30px;
  height: 30px;
  border-bottom: 1px solid lightgrey;
  background-color: #fff;
  cursor: pointer;
  padding-left: 10px;
}

.usersSuggestionsItem:hover {
  background-color: #ebebeb;
}

.usersSearchWrapper {
  position: relative;
  margin-left: 20px;
}

.usersSuggestionsEmpty {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.usersSuggestionsEmpty span {
  font-size: 18px;
}
