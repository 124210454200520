.enter {
  opacity: 0;
  transform: scale(0.9);
}
.enterActive {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 1000ms, transform 1000ms ease;
}
.exit {
  opacity: 1;
}
.exitActive {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 1000ms, transform 1000ms ease;
}
