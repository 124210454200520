.searchField {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.searchField input {
  border: 1px solid #777;
  outline: none;
  width: 250px;
  height: 30px;
  padding: 5px 10px;
}

.searchField label {
  margin-right: 5px;
}
