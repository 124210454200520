.navigationWrapper {
  width: calc(100vw - 60px);
  display: flex;
  padding: 10px 0 20px;
  justify-content: space-between;
  max-height: 60px;
  overflow-x: auto;
  margin: 0 auto;
}

.navigationFormWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

a {
  color: #000;
}

.navigationBtn {
  color: #000;
  background-color: #a7a7a7;
  height: 30px;
  padding: 0 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  position: relative;
}

.navigationBtn:hover {
  background-color: #b8b8b8;
}

.navigationBtn span {
  line-height: 16px;
}

.navigationPlusIcon {
  width: 20px;
  height: 20px;
  background-color: #000;
  cursor: pointer;
  mask: url(../../../../assets/icons/plus.svg) no-repeat center / cover;
  --webkit-mask: url(../../../../assets/icons/plus.svg) no-repeat center / cover;
  position: absolute;
  left: 5px;
  top: 5px;
}

.navigationSelect {
  height: 30px;
  width: 100px;
  outline: none;
}

.navigationFormWrapperItem {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigationFormWrapperItem label {
  margin-right: 5px;
}

.navigationHeader {
  margin: 0;
}
