.createTopic {
  background: #d3d3d3;
  height: calc(100vh - 50px);
  overflow-y: auto;
}

.createTopicForm {
  min-height: 400px;
  width: 500px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 35px 25px 40px;
  position: relative;
}

.createTopicForm label {
  margin-bottom: 10px;
}

.createTopicForm textarea {
  resize: none;
  height: 105px;
  outline: none;
  padding: 10px;
}

.createTopicForm select {
  margin-bottom: 15px;
}

.createTopicForm input,
.createTopicForm select {
  height: 40px;
  outline: none;
  padding-left: 5px;
}

.createTopicForm textarea,
.createTopicForm select,
.createTopicForm input {
  border: 1px solid black;
}

.createTopicWrapper {
  display: flex;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.createTopicSubmitBtn {
  height: 40px;
  width: 150px;
  background-color: #b8b8b8;
  border: 2px solid #b8b8b8;
  border-radius: 0;
  cursor: pointer;
  font-size: 17px;
  outline: none;
}

.createTopicCancelBtn {
  composes: createTopicSubmitBtn;
  background-color: #fff;
}

.createTopicLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.createTopicText {
  color: #d3d3d3;
  margin: 15px 0 0 auto;
}

.createTopicButtonsWrapper {
  display: flex;
  width: 100%;
  max-width: 320px;
  justify-content: space-between;
  margin: 30px auto 0;
}

.createTopicHeading {
  margin-bottom: 20px;
  margin-right: auto;
}

.createTopicInner {
  width: 500px;
  padding-top: 100px;
}

.createTopicAnswersContainer {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  position: relative;
}

hr {
  width: 498px;
  background-color: #b8b8b8;
  position: absolute;
  top: 280px;
  left: 0;
}

.createTopicAnswersRow {
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;
}

.createTopicAnswersRowInner {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.createTopicAnswersContainer input {
  min-height: 40px;
}

.createTopicDeleteIcon {
  cursor: pointer;
  min-width: 20px;
  height: 20px;
  background-color: #565656;
  mask: url(../../../assets/icons/delete.svg) no-repeat center / contain;
  --webkit-mask: url(../../../assets/icons/delete.svg) no-repeat center / contain;
  margin: 0 10px 10px;
}

.createTopicDeleteIcon:hover {
  background-color: darkred;
}
.createTopicDeleteIconDisabled {
  composes: createTopicDeleteIcon;
  background-color: #b8b8b8;
  cursor: not-allowed;
}

.createTopicDeleteIconDisabled:hover {
  background-color: #b8b8b8;
}
