.editTopicForm {
  height: calc(100% - 50px);
  overflow: auto;
  padding: 0 20px;
}

.editTopicFormWrapper {
  background-color: #fff;
  position: relative;
}

.editTopicSurveyWrapper {
  padding-bottom: 20px;
}

.editTopicFormRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.editTopicFormRow label {
  margin-bottom: 5px;
}

.editTopicFormRow input {
  height: 30px;
}

.editTopicFormRow input:disabled,
.editTopicFormRow textarea:disabled {
  background-color: #e1e1e1;
}

.editTopicFormRow textarea {
  height: 85px;
  resize: none;
}

.editTopicFormRow input,
.editTopicFormRow textarea {
  border: 1px solid #000;
  outline: none;
  padding: 5px 10px;
}

.editTopicFormInputWrapper {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.editTopicFormInputWrapperFull {
  composes: editTopicFormInputWrapper;
  width: 100%;
}

.editTopicFormTextareaWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.editTopicFormHeading {
  font-size: 16px;
  font-weight: 700;
}

.editTopicFormHeader {
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.editTopicSurveyHeader {
  composes: editTopicFormHeader;
  padding: 0;
}

.editTopicFormLoading {
  position: absolute;
  top: 44px;
  left: 0;
  height: 6px;
  width: 100%;
}
