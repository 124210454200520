.banUserModal {
  max-width: 450px;
  height: 100%;
  max-height: 200px;
  padding: 20px;
  /* semantic-ui */
  border-radius: 0 !important;
}

.banUserModalWrapper {
  height: 100%;
}

.banUserModalInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-top: 50px;
}

.banUserModalInner h3 {
  text-align: center;
  font-size: 20px;
}

.iconClose {
  width: 16px;
  height: 16px;
  background-color: #8a9197;
  mask: url(../../../../assets/icons/close.svg) no-repeat center / contain;
  --webkit-mask: url(../../../../assets/icons/close.svg) no-repeat center / contain;
  position: absolute;
  right: 0;
  top: 0;
  margin: 10px;
  cursor: pointer;
}

.banUserBtn {
  width: 130px;
  height: 30px;
  background: #b8b8b8;
  border: none;
  outline: none;
  cursor: pointer;
  margin-left: 10px;
}

/* 3 dots icon styles */
.userBan {
  position: absolute;
  top: 0;
  right: 15px;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 50px;
  background: #fff;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

.userBan .banUserBtn {
  font-size: 12px;
  line-height: 12px;
  width: auto;
  height: 25px;
  padding: 0 7px;
  margin: 0;
}

.userDotsIcon {
  cursor: pointer;
  height: 20px;
  width: 20px;
  background: #000;
  mask: url(../../../../assets/icons/dots.svg) no-repeat center / contain;
  --webkit-mask: url(../../../../assets/icons/dots.svg) no-repeat center / contain;
  margin-left: auto;
}

.userDotsIcon:hover + .userBan,
.userBan:hover {
  display: flex;
}
